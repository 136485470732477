<template>
  <div class="lp-preorder-modal">

    <div style="font-size: 30px; font-weight: 700;
      margin-bottom: 60px; text-align: center;">서비스 신청문의</div>

    <div style="margin-bottom: 20px;">
      <div class="modal-section">
        <div class="modal-subtitle">신청자 정보*</div>
        <input v-model="params.name" class="preorder-input" type="text" placeholder="이름">
        <input class="preorder-input"
          type="text" placeholder="연락처"
          :value="autoHyphenPhone(params.phone)"
          @input="e => params.phone = autoHyphenPhone(e.target.value)"
          >
        <input v-model="params.email" class="preorder-input" type="text" placeholder="이메일">
        <!-- <div class="modal-description">* 회원정보와 동일하지 않는 경우 변경 가능합니다</div> -->
      </div>

      <div class="modal-section">
        <div class="modal-subtitle">신청정보*</div>

        <input v-model="params.service_name" class="preorder-input" type="text" placeholder="서비스명">
        <div class="preorder-input flex" style="padding:7px 12px">
          <input type="text"
                 placeholder="서비스 아이디"
                 maxlength="30"
                 :value="params.service_id"
                 @input="e => params.service_id = e.target.value"
                 style="width:50%;border:0;padding:7px 0px;">
          <div style="padding:4px 0">.launchpack.co.kr</div>
        </div>
      </div>

      <div class="modal-section">
        <div class="preorder-summary">
          <div class="summary-section">
            저는 사용자가
            <span class="template-bold">{{ selectedValue.selectedPack }}</span>을(를) 하면
            <span class="template-bold">{{ selectedValue.selectedPlatform }}</span>가
            <span class="template-bold">{{ selectedValue.selectedOrder }}</span>하는 서비스를 만들고 싶어요.
          </div>

          <div class="summary-section">
            사용자들이 주문하기 전에
            <span class="template-bold">{{ selectedValue.selectedPlatform }}</span>에게 문의를 할 수 있게 상품 상세페이지에
            <span class="template-bold">{{ selectedValue.selectedInquiry }}</span>가 있었으면 좋겠어요.
            제 서비스에서는 결제는 <span class="template-bold">{{ selectedValue.selectedPayment }}</span> 거에요.
            그 밖의 기능으로 회원등급이 <span class="template-bold">{{ selectedValue.selectedUserGrade }}</span>해요.
            그리고 서비스를 제공하는 사람은 등급이 <span class="template-bold">{{ selectedValue.selectedPartnerGrade }}</span> 좋겠어요.
          </div>

          <div class="summary-section">
            제가 생각하는 페이지 디자인은

            메인페이지는
            <span v-if="selectedValue.main_upload[0].name" class="lp-template-item template-highlight">{{ selectedValue.main_upload[0].name }}</span>
            <span v-if="selectedValue.main_upload[1].name" class="lp-template-item template-highlight">{{ selectedValue.main_upload[1].name }}</span>
            이렇게,<br>

            상품목록 페이지는
            <span v-if="selectedValue.product_upload[0].name" class="lp-template-item template-highlight">{{ selectedValue.product_upload[0].name }}</span>
            <span v-if="selectedValue.product_upload[1].name" class="lp-template-item template-highlight">{{ selectedValue.product_upload[1].name }}</span>
            이렇게,<br>

            그리고 상품상세 페이지는
            <span v-if="selectedValue.product_detail_upload[0].name" class="lp-template-item template-highlight">{{ selectedValue.product_detail_upload[0].name }}</span>
            <span v-if="selectedValue.product_detail_upload[1].name" class="lp-template-item template-highlight">{{ selectedValue.product_detail_upload[1].name }}</span>
            이렇게,<br>

            <span v-if="selectedValue.blank_page" class="template-bold">{{ selectedValue.blank_page }}</span> 페이지는
            <span v-if="selectedValue.blank_upload[0].name" class="lp-template-item template-highlight">{{ selectedValue.blank_upload[0].name }}</span>
            <span v-if="selectedValue.blank_upload[1].name" class="lp-template-item template-highlight">{{ selectedValue.blank_upload[1].name }}</span>
            이렇게 하려고 했어요.
          </div>

        </div>
      </div>

      <div class="modal-section">
        <div class="modal-subtitle">추가 문의내용</div>
        <textarea
          v-model="params.counsel"
          class="preorder-input additional-inquiry"
          placeholder="추가 문의사항을 입력해주세요"></textarea>
      </div>

      <!-- 버튼 -->
      <div class="next-page-btn-wrap">
        <div class="next-page-btn"
          @click="makeInquiry">신청 문의하기</div>
      </div>

    </div>
  </div>
</template>

<script>

  export default {

    name: "lp-preorder-modal",

    props: {
      selectedValue: {
        type: Object
      }
    },

    components: {
    },

    data() {
      return {
        params: {
          user_id: 0,
          counsel: '',
          name: '',
          email: '',
          phone: '',
          price: 8000000,
          type: '',
          products: [23, 38, 35, 29, 19],
          main_upload: [],
          product_upload: [],
          product_detail_upload: [],
          blank_upload: [],
          blank_page: '',
          serviceName: '',
          service_id: ''
        },

        errorMsg: ''    // validation 에러 메세지
      }
    },

    created() {
      if(this.$store.getters.user.name) {
        let user = this.cloneItem(this.$store.getters.user)
        let user_id = '';
        if (user.user_id > 0) user_id = user.user_id;
        this.params.user_id = user_id;
        this.params.name = user.name;
        this.params.email = user.email;
        this.params.phone = user.phone;
      }
      ['main_upload', 'product_upload', 'product_detail_upload',
        'blank_upload', 'blank_page', 'pay_type', 'price',
        'order_conversion_type', 'inquiry_conversion_type',
        'payment_type', 'plugins', 'package', 'products'].forEach(item => {
        if(item.indexOf('_upload')>-1) {
          this.params[item] = [];
          this.selectedValue[item].forEach(child => {
            this.params[item].push(child.file);
          });
        }
        else
          this.params[item] = this.selectedValue[item];
      });
    },

    watch: {
    },

    methods: {
      // 문의하기
      makeInquiry() {
        if (!this.validate()) {
          this.toast(this.errorMsg);
          this.errorMsg = '';
          return;
        }
        else {
          this.$axios.post('public/launchpack/theme/request', this.params)
          .then(res => {
            if (res.status === 200) {
              this.toast('신청 문의가 완료되었습니다.');
              this.errorMsg = '';
              this.$emit('closeModal');
            }
          })
          .catch(error => {
            console.log(error);
          })
        }
      },

      validate() {
        let pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        //let pattern_phone = /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/g;
        let pattern_email = /[0-9a-zA-Z][_0-9a-zA-Z-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+){1,2}$/;    //이메일

        if ( !(pattern_kor.test(this.params.name)) || this.params.name.length===0 ) {
          this.errorMsg = '이름을 입력해주세요.'
          return false;
        }
        // else if ( !(pattern_phone.test(this.params.phone)) ) {
        else if ( this.params.phone.length<11 && this.params.phone.length>13 ) { // autoHyphenPhone로 length만 체크
          this.errorMsg = '연락처를 입력해주세요.'
          return false;
        }
        else if ( !(pattern_email.test(this.params.email)) ) {
          this.errorMsg = '올바른 이메일 형식이 아닙니다.'
          return false;
        }
        else {
          return true;
        }
      }
    }

  }
</script>

<style scoped>

  @media (max-width: 600px) {
    .preorder-input {
      width: 100%;
    }
  }
  @media (min-width: 601px) {
    .preorder-input {
      width: 480px;
    }
  }

  .lp-preorder-modal {
    text-align: left;
    color: #393e46;
    font-weight: 400;
    padding: 0 32px;
    height: 80vh;
  }

  .modal-section {
    margin-bottom: 36px;
  }

  .modal-subtitle {
    color: #7f7f7f;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 8px;
  }

  .modal-description {
    color: #c7c7c7;
    font-size: 12px;
    line-height: 18px;
  }

  .preorder-input {
    border: 1px solid #e6e6e6;
    border-radius: 12px;
    padding: 14px 12px;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .additional-inquiry {
    height: 124px;
  }

  .additional-inquiry::-webkit-input-placeholder {
  color: #e6e6e6;
  }
  .additional-inquiry::-moz-placeholder {
    color: #e6e6e6;
  }
  .additional-inquiry:-ms-input-placeholder {
    color: #e6e6e6;
  }
  .additional-inquiry::placeholder {
    color: #e6e6e6;
  }

  .preorder-summary {
    font-size: 12px;
    color: #393e46;
  }

  .summary-section {
    margin-bottom: 12px;
  }

  .template-highlight {
    color: #7ad4ef;
  }

  .next-page-btn-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .next-page-btn {
    width: 180px;
    height: 48px;
    background: #ff6600;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

</style>
